import React, { useEffect } from "react"
import type { ComponentType } from "react"

export function withActiveFilterInView(Component): ComponentType {
    return (props) => {
        useEffect(() => {
            let scrollTimeout = null

            const ensureActiveElementInView = () => {
                if (scrollTimeout) {
                    clearTimeout(scrollTimeout)
                }

                scrollTimeout = setTimeout(() => {
                    const filterComponent = document.querySelector(
                        '[data-framer-name="Filter"]'
                    )
                    const selectedElement = filterComponent.querySelector(
                        '[data-framer-name="Selected State"]'
                    )

                    if (filterComponent && selectedElement) {
                        selectedElement.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                            inline: "start",
                        })
                    }
                }, 200)
            }

            window.addEventListener("scroll", ensureActiveElementInView)
            return () => {
                window.removeEventListener("scroll", ensureActiveElementInView)
                if (scrollTimeout) {
                    clearTimeout(scrollTimeout)
                }
            }
        }, [])

        return <Component {...props} />
    }
}
